import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';

import { Link } from 'gatsby';

const ProductCard = (props: IProductCardProps) => {
  const image = getImage(props.coverImg);
  let categorySlug = props.category?.slug ?? '';
  let productUrl = '/' + categorySlug + '/' + props.slug;

  return (
    <div className={`col-12 col-md-6`}>
      <div className="row">
        <div className="col-12">
          {image && (
            <Link to={productUrl}>
              <GatsbyImage image={image} objectFit="cover" alt={props.name} />
            </Link>
          )}
        </div>
      </div>
      <div className="row py-3">
        <div className="col-12">
          <h2 className="p-0 mt-3">
            <Link to={productUrl}>{props.name}</Link>
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-5">
          {documentToReactComponents(props.excerpt)}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
