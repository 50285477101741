import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';

import Hero from '../components/hero/hero';
import Layout from '../components/layout';
import ProducstList from '../components/productsList/productsList';

const WoodTurningPage = () => {
  return (
    <StaticQuery
      query={graphql`
        query WoodturningProductsQuery {
          page: contentfulPage(slug: { eq: "woodturning" }) {
            name
            slug
            excerpt {
              raw
            }
            content {
              raw
            }
            coverImage {
              file {
                url
                fileName
              }
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          allContentfulProduct(
            filter: { category: { name: {}, slug: { eq: "woodturning" } } }
          ) {
            nodes {
              name
              slug
              excerpt {
                raw
              }
              category {
                slug
                name
              }
              coverImage {
                file {
                  url
                  fileName
                }
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  formats: [AUTO, WEBP, AVIF]
                  layout: FULL_WIDTH
                  width: 240
                  height: 240
                )
              }
            }
          }
        }
      `}
      render={(data) => {
        console.dir(data);
        let page: IPageProps = data.page;
        let image: IGatsbyImageData | undefined = getImage(page.coverImage);

        return (
          <Layout>
            {image && <Hero background={page.coverImage.file.url} />}
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1 className="pt-2 pb-4">{page.name}</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {page.content
                    ? documentToReactComponents(JSON.parse(page.content.raw))
                    : ''}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <ProducstList products={data.allContentfulProduct.nodes} />
                </div>
              </div>
            </div>
          </Layout>
        );
      }}
    />
  );
};
export default WoodTurningPage;
