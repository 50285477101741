import React from 'react';
import * as styles from './hero.module.scss';

interface HeroProps {
  position?: 'top' | 'bottom' | 'center';
  background: string;
}

const Hero = (props: HeroProps) => {
  return (
    <div className={`container-fluid px-0 pt-1 pb-5`}>
      <div
        className={`${styles.heroContent}`}
        style={{
          backgroundImage: `url(${props.background})`,
          backgroundPosition: `${props.position ? props.position : 'center'}`,
        }}
      ></div>
    </div>
  );
};

export default Hero;
