import React from 'react';
import ProductCard from '../productCard/productCard';

interface ProductListProps {
  products: [];
}

// https://www.gatsbyjs.com/plugins/gatsby-plugin-image/#installation
const ProductsList = (props: ProductListProps) => {
  return (
    <>
      {props.products.length > 0 ? (
        <div className="row gx-5">
          {props.products.map((node: any, index) => (
            <ProductCard
              key={node.slug + index}
              slug={node.slug}
              name={node.name}
              excerpt={node.excerpt ? JSON.parse(node.excerpt.raw) : ''}
              coverImg={node.coverImage}
              category={node.category}
            />
          ))}
        </div>
      ) : (
        <div>No projects here yet</div>
      )}
    </>
  );
};

export default ProductsList;
